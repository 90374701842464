<template>
  <div class="centered">
    <div class="">
      <div
        class="bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 p-2 max-w-5xl"
      >
        <div class="max-h-96 rounded-lg overflow-hidden">
          <img
            class="rounded-lg object-cover object-center w-full h-full"
            style="width: 1060px"
            src="https://img.evbuc.com/https%3A%2F%2Fcdn.evbuc.com%2Fimages%2F472489619%2F168299195030%2F1%2Foriginal.20230319-133104?w=940&auto=format%2Ccompress&q=75&sharp=10&rect=0%2C0%2C2160%2C1080&s=9d52c052f8d6f33caae7341f7066feac"
          />
        </div>
        <div class="md:p-5 p-2 relative">
          <div
            class="flex-none rounded-lg overflow-hidden w-16 h-16 text-center border absolute right-0 top-0 mt-6 mr-6 md:block hidden"
          >
            <div class="block text-center">
              <div class="bg-blue-500 text-sm text-white">MAR</div>
              <div class="px-2">
                <span class="text-2xl font-bold leading-relaxed"> 7 </span>
              </div>
            </div>
          </div>

          <div class="mb-4">
            <a href="#">
              <h5
                class="mb-2 text-3xl font-semibold tracking-tight text-gray-900 dark:text-white"
              >
                Casciari a la carta
              </h5>
            </a>
            <div class="flex items-center space-x-2">
              <img
                class="w-6 h-6 rounded-full ring-2 ring-gray-300 dark:ring-gray-500"
                src="https://i.pravatar.cc/300"
                alt="Bordered avatar"
              />

              <p class="font-normal text-sm text-gray-700 dark:text-gray-400">
                Producción <strong>Orsai</strong>
              </p>
            </div>
          </div>
          <hr class="my-2" />
          <!-- Dos columnas -->
          <div class="flex mb-3 flex-wrap space-y-4">
            <div class="flex items-center space-x-4 md:w-1/2">
              <!-- component -->
              <!-- Calendar Icon -->
              <div
                class="flex-none rounded-lg overflow-hidden w-12 h-12 text-center border"
              >
                <div class="block text-center">
                  <div class="bg-blue-500 text-xxs text-white">MAR</div>
                  <div class="px-2">
                    <span class="text-lg font-bold leading-relaxed"> 7 </span>
                  </div>
                </div>
              </div>

              <div class="text-sm">
                <div class="font-semibold">Jueves, 10 de Agosto</div>
                <div>21:00 to 23:00 (ARG GMT-3)</div>
              </div>
            </div>
            <div class="flex items-center space-x-4 md:w-1/2">
              <!-- component -->
              <!-- Calendar Icon -->
              <div
                class="flex rounded-lg border w-12 h-12 items-center justify-center"
              >
                <!-- Icon place -->
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z"
                  />
                </svg>
              </div>
              <div class="text-sm">
                <div class="font-semibold">The Roxy - CABA</div>
                <div>Federico Lacroze y Álvarez Thomas</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="flex max-w-5xl mt-4 w-full mb-2 flex-wrap flex-col-reverse md:flex-row"
    >
      <div class="md:w-3/5 md:pr-4">
        <div
          class="px-3 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700"
        >
          <div class="flex space-x-2 items-center border-b -mx-3 px-3 py-3">
            <svg
              class="w-3 h-3 text-gray-500 dark:text-gray-400"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                d="M18 5h-.7c.229-.467.349-.98.351-1.5a3.5 3.5 0 0 0-3.5-3.5c-1.717 0-3.215 1.2-4.331 2.481C8.4.842 6.949 0 5.5 0A3.5 3.5 0 0 0 2 3.5c.003.52.123 1.033.351 1.5H2a2 2 0 0 0-2 2v3a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V7a2 2 0 0 0-2-2ZM8.058 5H5.5a1.5 1.5 0 0 1 0-3c.9 0 2 .754 3.092 2.122-.219.337-.392.635-.534.878Zm6.1 0h-3.742c.933-1.368 2.371-3 3.739-3a1.5 1.5 0 0 1 0 3h.003ZM11 13H9v7h2v-7Zm-4 0H2v5a2 2 0 0 0 2 2h3v-7Zm6 0v7h3a2 2 0 0 0 2-2v-5h-5Z"
              />
            </svg>
            <h5
              class="text-normal font-semibold tracking-tight text-gray-900 dark:text-white"
            >
              Detalles del evento
            </h5>
          </div>
          <div class="m-3">
            <div>
              <h1 class="text-2xl font-semibold mb-4">
                Evento: Conferencia de Innovación Tecnológica
              </h1>
              <p class="mb-4">
                Te invitamos a participar en nuestra
                <span class="font-bold"
                  >emocionante conferencia de innovación tecnológica</span
                >, donde exploraremos las últimas tendencias y avances en el
                mundo de la tecnología. Será una oportunidad única para
                conectarte con
                <span class="font-bold">expertos de la industria</span>,
                compartir conocimientos y descubrir cómo la tecnología está
                transformando nuestro mundo.
              </p>

              <div class="mb-4">
                <p>
                  <span class="font-bold">Fecha:</span> 15 de septiembre de 2023
                </p>
                <p><span class="font-bold">Hora:</span> 9:00 AM - 5:00 PM</p>
                <p>
                  <span class="font-bold">Lugar:</span> Centro de Convenciones
                  TecnoCity
                </p>
              </div>

              <p class="mb-4">
                Destacados oradores de
                <span class="font-bold">empresas líderes en la industria</span>
                compartirán sus perspectivas sobre
                <span class="italic">inteligencia artificial</span>,
                <span class="italic">Internet de las cosas</span>,
                <span class="italic">realidad virtual</span>, y mucho más.
                Además de las presentaciones en el escenario principal, habrá
                <span class="font-bold"
                  >sesiones interactivas y talleres prácticos</span
                >
                donde podrás profundizar en temas específicos y aprender nuevas
                habilidades.
              </p>

              <p class="mb-4">
                No te pierdas esta oportunidad de estar a la
                <span class="font-bold"
                  >vanguardia de la innovación tecnológica</span
                >. <span class="font-bold">Regístrate ahora</span> y asegura tu
                lugar en esta conferencia imperdible. ¡Esperamos verte allí!
              </p>

              <p class="mb-4">
                Para registrarte y obtener más información, visita nuestro
                <a
                  href="https://www.ejemploevento.com"
                  class="text-blue-500 underline"
                  >sitio web del evento</a
                >.
              </p>

              <p>¡Nos vemos en la conferencia!</p>

              <img
                src="https://teatroamazonas.com.br/wp-content/uploads/2021/11/NOVO-MAPA-DA-PLATEIA-2021-COM-REDUCAO-75-SO-IMAGEM-2048x1824.png"
                alt="Imagen de demostración"
                class="mt-8 rounded-lg border"
              />
            </div>

            <p class="text-gray-600">
              Para consultas, comunícate con nosotros en
              <span class="text-blue-500">info@ejemploevento.com</span>
            </p>
          </div>
        </div>
      </div>
      <div class="md:w-2/5">
        <div
          class="px-3 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 mb-4"
        >
          <div class="flex space-x-2 items-center border-b -mx-3 px-3 py-3">
            <svg
              class="w-3 h-3 text-gray-500 dark:text-gray-400"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path
                d="M18 5h-.7c.229-.467.349-.98.351-1.5a3.5 3.5 0 0 0-3.5-3.5c-1.717 0-3.215 1.2-4.331 2.481C8.4.842 6.949 0 5.5 0A3.5 3.5 0 0 0 2 3.5c.003.52.123 1.033.351 1.5H2a2 2 0 0 0-2 2v3a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V7a2 2 0 0 0-2-2ZM8.058 5H5.5a1.5 1.5 0 0 1 0-3c.9 0 2 .754 3.092 2.122-.219.337-.392.635-.534.878Zm6.1 0h-3.742c.933-1.368 2.371-3 3.739-3a1.5 1.5 0 0 1 0 3h.003ZM11 13H9v7h2v-7Zm-4 0H2v5a2 2 0 0 0 2 2h3v-7Zm6 0v7h3a2 2 0 0 0 2-2v-5h-5Z"
              />
            </svg>
            <h5
              class="text-normal font-semibold tracking-tight text-gray-900 dark:text-white"
            >
              Registro
            </h5>
          </div>
          <div class="m-3">
            <p class="mb-3 text-sm leading-relaxed">
              Al reservar la entrada, se le consulta a cada participante si
              desea una función de llorar, de reír o de pensar. Ellos deciden
              qué cuentos leerá el autor y sobre qué temas deberá improvisar.
              <span class="font-semibold"
                >Podrá haber escritores mejores que Casciari, pero ninguno tan
                obsecuente.</span
              >
            </p>
            <ul
              class="w-100 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white"
            >
              <li
                class="w-full border-b border-gray-200 rounded-t-lg dark:border-gray-600"
              >
                <div class="flex items-center pl-3">
                  <input
                    id="list-radio-license"
                    type="radio"
                    value=""
                    checked
                    name="list-radio"
                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500"
                  />
                  <label
                    for="list-radio-license"
                    class="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                  >
                    <div>Entrada general</div>
                    <!-- <div class="text-xs font-normal">
                      Incluye 1 (una) entrada al evento
                    </div> -->
                  </label>
                  <!-- Price -->
                  <div class="pr-3">
                    <!-- 100 USD -->
                    <div
                      class="text-md font-semibold text-gray-900 flex-no-wrap"
                    >
                      100USD
                    </div>
                  </div>
                </div>
              </li>
            </ul>

            <!-- Boton full width -->
            <button
              class="w-full mt-4 text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded text-sm px-5 py-2.5 text-center mr-2 mb-2"
            >
              Conseguir entradas
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  layout: "empty",
};
</script>

<style>
.text-xxs {
  font-size: 0.6rem !important;
}
.centered {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
}
</style>